<template>
  <div>
    <div class="p-center">
      <div v-for="(item,index) in csListData" :key="index">
        <div class="right_box">
          <div>
            <el-tooltip popper-class="testtooltip" placement="left" effect="light" v-if="item.type==3">
              <div slot="content">{{ item.info }}</div>
              <img class="severImg" src="@/assets/images/phone_r.png" alt="">
            </el-tooltip>
            <a class="severCon"  title="点击这里给我发消息"
               :href="'http://wpa.qq.com/msgrd?v=3&amp;uin='+item.info+'&amp;site=qq&amp;menu=yes'"
               target="_blank"  v-if="item.type==2">
              <img class="severImg" src="@/assets/images/qq_r.png" alt="">
            </a>
            <el-tooltip popper-class="testtooltip" placement="left" effect="light"  v-if="item.type==1">
              <div slot="content">
                <!-- <img :src="$baseImageUrl + item.info" alt="" style="width: 180px;height: 180px"> -->
                <img src="https://static.catduoduo.com/assets/images/mingzhentankefu.png" alt="" style="width: 180px;height: 180px">
                <div style="text-align: center;margin-top: 10px">{{item.name}}</div>
              </div>
              <img class="severImg" src="@/assets/images/weChat_r.png" alt="">
            </el-tooltip>
            <!--          <el-tooltip popper-class="testtooltip" placement="left" effect="light"  v-if="item.type==4">-->
            <!--            <div slot="content">-->
            <!--              <img :src="$baseImageUrl + item.info" alt="" style="width: 180px;height: 180px">-->
            <!--            </div>-->
            <!--            <img class="severImg" src="@/assets/images/weChat_r2.png" alt="" style="border-radius: 6px">-->
            <!--          </el-tooltip>-->
          </div>
        </div>
        <div  v-if="item.type==4" class="gzBox">
          <!-- <img :src="$baseImageUrl + item.info" alt="" style="width: 180px;height: 180px"> -->
          <img src="https://static.catduoduo.com/assets/images/mingzhentankefu.png" alt="" style="width: 180px;height: 180px">
          <!-- <div style="text-align: center;margin-top: 10px">{{item.name}}</div> -->
          <div style="text-align: center;margin-top: 10px">微信客服</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      csListData:{}
    }
  },
  mounted() {
    this.getCsList()
  },
  methods: {
    async getCsList(){
      let res = await this.$http({
        url: `member/login/cs_list`,
        method: "post",
        data: {
        }
      });
      const {code, msg , data} = res;
      if (code === 200) {
        this.csListData = data
      }
    },
  }
}
</script>

<style scoped lang="less">
  .right_box{
    position: fixed;
    z-index: 100;
    right: 10%;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .severCon{
      margin: 10px 0 7px;
    }
    .severImg{
      width: 42px;
      height: 42px;
      cursor: pointer;
    }
  }
  .gzBox{
    position: fixed;
    z-index: 100;
    right: 3%;
    bottom: 5%;
  }

</style>
<style  lang="less">
.testtooltip{
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1)!important;
  border: 1px solid #E5E5E5!important;
}
.el-tooltip__popper[x-placement^="left"] .popper__arrow {
  border-left-color: white!important;
}
.el-tooltip__popper[x-placement^="left"] .popper__arrow:after {
  border-left-color: white !important;
}
</style>
